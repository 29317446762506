// eslint-disable-next-line import/prefer-default-export
export const aboutUsHero = {
  title: "Your business is our business",
  subtext:
    "SpotOn was built to help businesses compete and win with intuitive software, point-of-sale, and payment solutions.\n",
  heroImg: "right.png",
  PrimaryCta: {
    ctaTitle: "Talk to our team",
    ctaTarget: "https://help.spoton.com/page/contact-us",
  },
  // SecondaryCta: {
  //   ctaTitle: 'Learn what SpotOn can do for your business',
  //   ctaTarget: '/page-2',
  // },
};

export const aboutUsTrusted = [
  {
    title: "$928 million",
    subtext: "Financial backing",
  },
  {
    title: "2,000+",
    subtext: "New businesses monthly",
  },
  // {
  //   title: "6",
  //   subtext: "Locations worldwide",
  // },
  {
    title: "2,000+",
    subtext: "Innovative employees",
  },
];

export const aboutUsWhiteGlove = {
  mainTitle: "The SpotOn way",
  title: "Because business is personal",
  content:
    "As a business owner, it can feel like you’re carrying the weight of the world on your shoulders. We’re here to help. At SpotOn, we don’t just create innovative tools to run and sustain your business. We also give you the in-person service and support you deserve to get the most from them.",
  ctaInfoPrimary: {
    ctaTitle: "Talk to our team",
    ctaTarget: "https://help.spoton.com/page/contact-us",
  },
};

export const aboutUsNoVisual = {
  title: "Our mission",
  subtext:
    "SpotOn is committed to supporting businesses at the heart of our communities with the software and payment solutions they need, supported by local and personal service, delivered at a fair price, and designed to help them compete and win. We believe that business is personal and our clients—big or small, national or local—should never be on their own. We are right beside them, delivering the future technology they need, today and every day.",
};

export const aboutUsLarge = {
  sectionNavId: "helping",
  featureBlocks: [
    {
      blockTitle: "Helping businesses at the core of our communities",
      blockSubTitle:
        "We’ve created an ecosystem of software and payment processing solutions that empower you to work more efficiently, more strategically, and more economically. Equally important, we give you the support you need to use them, from real experts who have been in your shoes.",
      blockImg: "about-us-team.png",
      forceReverse: false,
    },
  ],
};

export const aboutUsVideo = {
  title: "We're here to grow with you, not because of you",
  videoBtn: "Watch video",
  posterImg: "team_spoton.jpg",
  videoUrlId: "8QVvWcGplAw",
};

export const aboutUsUsCarousel = {
  title: "Celebrating a diversity of ideas, experiences, and cultures",
  subtext:
    "Good ideas come from all walks of life, so we make a point of creating new opportunities for success—for our employees and clients alike. We actively promote innovation, teamwork, and creative solutions to problems, and we’re proud to work with diverse businesses across the nation.",
  images: ["image-office.png", "image-team.png", "support-guy.png"],
};

export const aboutUsSmallCTA = {
  title: "Career opportunities with SpotOn.",
  ctaInfo: {
    ctaTitle: "See all current openings",
    ctaTarget: "/careers",
  },
};
