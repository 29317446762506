import React from "react";
import loadable from "@loadable/component";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import Hero from "../../components/Hero/hero";
import { restaurantCta } from "../../data/restaurant-data";
import {
  aboutUsHero,
  aboutUsWhiteGlove,
  aboutUsSmallCTA,
  aboutUsLarge,
  aboutUsTrusted,
  aboutUsNoVisual,
  aboutUsUsCarousel,
  aboutUsVideo,
} from "../../data/about-us";
// import { tempData } from '../../data/article-data';
import { heroSuccess, heroSlides } from "../../data/success-stories-data";
// import LargeFeatures from "../../components/LargeFeatures/LargeFeatures";
// import WhiteGlove from "../../components/WhiteGlove/WhiteGlove";
// import VideoSection from "../../components/VideoSection/VideoSection";
// import SuccessStories from "../../components/SuccessStories/SuccessStories";
// import LargeCta from "../../components/CTAs/LargeCta";
// import SmallCta from "../../components/CTAs/SmallCta";
// import TrustedNumbers from "../../components/TrustedNumbers/TrustedNumbers";
// import NoVisuals from "../../components/NoVisuals/NoVisuals";
// import Offices from "../../components/Offices/Offices";
// import UsCarousel from "../../components/UsCarousel/UsCarousel";
// import OurValues from "../../components/OurValues/OurValues";
import ogImage from "../../images/global_assets/og-image.png";

const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/LargeFeatures")
);
const WhiteGlove = loadable(() =>
  import("../../components/WhiteGlove/WhiteGlove")
);
const VideoSection = loadable(() =>
  import("../../components/VideoSection/VideoSection")
);
const SuccessStories = loadable(() =>
  import("../../components/SuccessStories/SuccessStories")
);
const LargeCta = loadable(() => import("../../components/CTAs/LargeCta"));
const SmallCta = loadable(() => import("../../components/CTAs/SmallCta"));
const TrustedNumbers = loadable(() =>
  import("../../components/TrustedNumbers/TrustedNumbers")
);
const NoVisuals = loadable(() =>
  import("../../components/NoVisuals/NoVisuals")
);
const Offices = loadable(() => import("../../components/Offices/Offices"));
const UsCarousel = loadable(() =>
  import("../../components/UsCarousel/UsCarousel")
);
const OurValues = loadable(() =>
  import("../../components/OurValues/OurValues")
);

const whiteGloveImport = "team_spoton.jpg";

const Restaurants = () => {
  return (
    <Layout className="about-us">
      <SEO
        title="About SpotOn's Point of Sale & Software Solutions  | SpotOn"
        description="Learn all you need to know about SpotOn's pos system & software management solutions.  Our all-in-one mobile & desktop pos management is perfect for all businesses & teams. Get a demo."
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/pizza-pos"
      />
      <Hero sectionData={aboutUsHero} />
      {/* <TrustedNumbers numbersArray={aboutUsTrusted} /> */}
      <WhiteGlove
        sectionData={aboutUsWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <NoVisuals sectionData={aboutUsNoVisual} />
      <OurValues />
      <LargeFeatures sectionData={aboutUsLarge} />
      <VideoSection sectionData={aboutUsVideo} />
      <UsCarousel sectionData={aboutUsUsCarousel} />
      <SmallCta sectionData={aboutUsSmallCTA} />
      <SuccessStories sectionData={heroSuccess} slides={heroSlides} />
      {/*      <Articles
        sectionData={restaurantArticles}
        blogEntries={tempData}
        cornerStone={false}
        style={{ padding: '0 !important' }}
      /> */}
      <Offices />
      <LargeCta sectionData={restaurantCta} />
    </Layout>
  );
};

export default Restaurants;
